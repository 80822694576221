// import React, {useEffect, useRef, useState} from 'react';
// import { useTelegram } from '../../../TelegramContext';
// import {useLocation, useNavigate} from "react-router-dom";
// import '../../../assets/css/style.css';
// import Menu from "../../../components/Menu";
// import use2FaAuth from "../../../hooks/use2FaAuth";
// import useTranslation from "../../../hooks/useTranslation";
// import navBack from '../../../assets/img/icons/nav-back.svg'
//
// const useQuery = () => {
//     return new URLSearchParams(useLocation().search);
// };
//
// function Settings2Fa () {
//     const {translation} = useTranslation();
//     const query = useQuery();
//     const type = query.get('type');
//
//     const { user, triggerVibration } = useTelegram();
//     const {
//         otpCreateSecret, otpCreateSecretLoading, otpCreateSecretError, fetchCreateOtpSecret,
//         otpEnable, otpEnableLoading, otpEnableError, fetchEnableOtp,
//         otpDisable, otpDisableLoading, otpDisableError, fetchDisableOtp,
//         authOtpData, authOtpDataLoading, authOtpDataError, fetchAuthOtp,
//     } = use2FaAuth();
//
//     const navigate = useNavigate();
//     const isMounted = useRef(false);
//
//     const [code1, setCode1] = useState('');
//     const [code2, setCode2] = useState('');
//     const [code3, setCode3] = useState('');
//     const [code4, setCode4] = useState('');
//     const [code5, setCode5] = useState('');
//     const [code6, setCode6] = useState('');
//
//     // Создаем 6 рефов для каждого поля
//     const codeRefs = [
//         useRef(null),
//         useRef(null),
//         useRef(null),
//         useRef(null),
//         useRef(null),
//         useRef(null),
//     ];
//
//     useEffect(() => {
//         if (type && type === 'enable') {
//             if (!isMounted.current) {
//                 fetchCreateOtpSecret();
//                 isMounted.current = true;
//             }
//         }
//     }, [type]);
//
//     useEffect(() => {
//         if (otpEnableError ) {
//             console.log(otpEnableError, 'otpEnableError');
//
//             if (otpEnableError?.kind == "WRONG_OTP_PASSCODE_ERR") {
//                 alert('Не верный код')
//             } else {
//                 alert('Что то пошло не так. Попробуйте еще раз.')
//             }
//         }
//     }, [otpEnableError]);
//     useEffect(() => {
//         if (otpCreateSecret) {
//             console.log(otpCreateSecret, 'otpCreateSecret');
//         }
//     }, [otpCreateSecret]);
//
//     useEffect(() => {
//         if (otpEnable) {
//             navigate('/settings');
//             console.log(otpEnable, 'otpEnable');
//         }
//
//         if (otpDisable) {
//             navigate('/settings');
//             console.log(otpDisable, 'otpDisable');
//         }
//
//         if (authOtpData && authOtpData.kind === 'OK') {
//             navigate('/');
//         }
//
//         console.log(authOtpData, 'authOtpData');
//     }, [otpEnable, authOtpData, otpDisable]);
//
//     const handleInputChange = (value, index) => {
//
//         console.log(value,index , '---0000=-=-')
//         if (/^\d$/.test(value) || value === '') {
//
//             const setters = [setCode1, setCode2, setCode3, setCode4, setCode5, setCode6];
//
//             // Устанавливаем значение в соответствующее поле
//             setters[index](value);
//
//             // Переход на следующее поле, если есть, и значение введено
//             if (value && index < codeRefs.length - 1) {
//                 codeRefs[index + 1].current?.focus();
//             }
//         }
//
//     };
//
//     const handleKeyDown = (e, index) => {
//         if (e.key === 'Backspace' && !e.target.value) {
//             // Если удаляем значение и поле пустое, переходим на предыдущее поле
//             if (index > 0) {
//                 codeRefs[index - 1].current?.focus();
//             }
//         }
//     };
//
//     const goBack = () => {
//         navigate(-1);
//     };
//
//     return (
//         <div className="wrapper">
//             <header className="header">
//                 <nav className="nav">
//                     <a style={{cursor: 'pointer'}} onClick={goBack} className="nav__item">
//                         <img
//                             style={{cursor: 'pointer'}}
//                             className="nav__icon"
//                             width="24"
//                             height="24"
//                             src={navBack}
//                             alt="arrow icon"
//                         />
//                     </a>
//                     <Menu />
//                 </nav>
//             </header>
//
//             <main className="main">
//                 <div className="content">
//                     <h1 className="title">Google Authenticator</h1>
//
//                     <form className="form" action="#">
//                         <h2 className="form__title"> {type == 'auth' ? translation?.typeCode : translation?.scanQrOrEnterCode}</h2>
//                         {type === 'enable' && (
//                             <div className="form__qr" style={{ width: '100%' }}>
//                                 {otpCreateSecret && (
//                                     <img
//                                         className="form__qr-img"
//                                         width="178"
//                                         height="174"
//                                         src={otpCreateSecret?.qrUrl}
//                                         alt="qr code"
//                                     />
//                                 )}
//                             </div>
//                         )}
//
//                         <div className="form__gorup">
//                             <div style={{display: 'flex'}}>
//                                 <label className="form__label" htmlFor="">{translation?.googleAuthCode}</label>
//                                 {/*{otpEnableError && otpEnableError?.kind == "WRONG_OTP_PASSCODE_ERR" &&*/}
//                                 {/*    <div style={{fontSize:11, color: 'red', marginLeft: 5}}>Не верный код</div>*/}
//                                 {/*}*/}
//                             </div>
//                             <div className={`form__codes ${authOtpDataError ? 'error' : ''}`}>
//                                 {[code1, code2, code3, code4, code5, code6].map((value, index) => (
//                                     <input
//                                         type="text"
//                                         key={index}
//                                         ref={codeRefs[index]} // Привязываем реф к полю
//                                         className="form__code"
//                                         maxLength="1"
//                                         value={value}
//                                         onChange={(e) => handleInputChange(e.target.value, index)}
//                                         onKeyDown={(e) => handleKeyDown(e, index)}
//                                     />
//                                 ))}
//                             </div>
//                         </div>
//                     </form>
//                 </div>
//             </main>
//
//             <footer className="footer">
//                 <div className="footer__inner">
//                     <div className="footer__btns" style={{cursor: 'pointer'}}>
//                         {code1 && code2 && code3 && code4 && code5 && code6 && (
//                             <div
//                                 onClick={() => {
//                                     const code = `${code1}${code2}${code3}${code4}${code5}${code6}`;
//
//                                     if (type === 'enable') {
//                                         fetchEnableOtp(otpCreateSecret?.secret, code);
//                                     } else if (type === 'disable') {
//                                         fetchDisableOtp(code);
//                                     } else if (type === 'auth') {
//                                         fetchAuthOtp(code);
//                                     }
//                                 }}
//                                 className="footer__btn btn"
//                             >
//                                 {translation?.done}
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </footer>
//         </div>
//     );
// }
//
// export default Settings2Fa;


import React, { useEffect, useRef, useState } from 'react';
import { useTelegram } from '../../../TelegramContext';
import { useLocation, useNavigate } from "react-router-dom";
import '../../../assets/css/style.css';
import Menu from "../../../components/Menu";
import use2FaAuth from "../../../hooks/use2FaAuth";
import useTranslation from "../../../hooks/useTranslation";
import navBack from '../../../assets/img/icons/nav-back.svg'

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

function Settings2Fa () {
    const { translation } = useTranslation();
    const query = useQuery();
    const type = query.get('type');

    const { user, triggerVibration } = useTelegram();
    const {
        otpCreateSecret, otpCreateSecretLoading, otpCreateSecretError, fetchCreateOtpSecret,
        otpEnable, otpEnableLoading, otpEnableError, fetchEnableOtp,
        otpDisable, otpDisableLoading, otpDisableError, fetchDisableOtp,
        authOtpData, authOtpDataLoading, authOtpDataError, fetchAuthOtp,
    } = use2FaAuth();

    const navigate = useNavigate();
    const isMounted = useRef(false);

    const [code1, setCode1] = useState('');
    const [code2, setCode2] = useState('');
    const [code3, setCode3] = useState('');
    const [code4, setCode4] = useState('');
    const [code5, setCode5] = useState('');
    const [code6, setCode6] = useState('');

    // Массив "сеттеров", чтобы удобнее заполнять поля циклом
    const codeSetters = [setCode1, setCode2, setCode3, setCode4, setCode5, setCode6];
    // Создаем 6 рефов для каждого поля
    const codeRefs = [
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
        useRef(null),
    ];

    useEffect(() => {
        if (type && type === 'enable') {
            if (!isMounted.current) {
                fetchCreateOtpSecret();
                isMounted.current = true;
            }
        }
    }, [type]);

    useEffect(() => {
        if (otpEnableError) {
            console.log(otpEnableError, 'otpEnableError');
            if (otpEnableError?.kind === "WRONG_OTP_PASSCODE_ERR") {
                alert('Не верный код');
            } else {
                alert('Что-то пошло не так. Попробуйте еще раз.');
            }
        }
    }, [otpEnableError]);

    useEffect(() => {
        if (otpCreateSecret) {
            console.log(otpCreateSecret, 'otpCreateSecret');
        }
    }, [otpCreateSecret]);

    useEffect(() => {
        if (otpEnable) {
            navigate('/settings');
            console.log(otpEnable, 'otpEnable');
        }
        if (otpDisable) {
            navigate('/settings');
            console.log(otpDisable, 'otpDisable');
        }
        if (authOtpData && authOtpData.kind === 'OK') {
            navigate('/');
        }
        console.log(authOtpData, 'authOtpData');
    }, [otpEnable, authOtpData, otpDisable]);

    const handleInputChange = (value, index) => {
        // Разрешаем только одну цифру или пустую строку (стирание)
        if (/^\d$/.test(value) || value === '') {
            codeSetters[index](value);

            // Если поле заполнено (value != ''), фокус переходит к следующему
            if (value && index < codeRefs.length - 1) {
                codeRefs[index + 1].current?.focus();
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !e.target.value) {
            // Если удаляем значение и поле уже пустое, переходим на предыдущее
            if (index > 0) {
                codeRefs[index - 1].current?.focus();
            }
        }
    };

    /**
     * При вставке (paste) собираем все цифры из буфера обмена
     * и заполняем оставшиеся поля (начиная с того поля, куда вставили).
     */
    const handlePaste = (e, index) => {
        e.preventDefault();
        const clipboardData = e.clipboardData.getData('text').replace(/\D/g, ''); // оставляем только цифры

        if (!clipboardData) return;

        // Пробегаемся по каждой цифре и записываем в state
        for (let i = 0; i < clipboardData.length; i++) {
            if (index + i >= 6) break; // всего 6 полей
            codeSetters[index + i](clipboardData[i]);
        }

        // Ставим фокус на последнее заполненное поле
        const lastIndex = Math.min(index + clipboardData.length - 1, 5);
        codeRefs[lastIndex].current?.focus();
    };

    const goBack = () => {
        navigate(-1);
    };

    // Собираем код из 6 символов
    const fullCode = `${code1}${code2}${code3}${code4}${code5}${code6}`;
    const isCodeComplete = fullCode.length === 6;

    return (
        <div className="wrapper">
            <header className="header">
                <nav className="nav">
                    <a style={{ cursor: 'pointer' }} onClick={goBack} className="nav__item">
                        <img
                            style={{ cursor: 'pointer' }}
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={navBack}
                            alt="arrow icon"
                        />
                    </a>
                    <Menu />
                </nav>
            </header>

            <main className="main">
                <div className="content">
                    <h1 className="title">Google Authenticator</h1>

                    <form className="form" action="#">
                        <h2 className="form__title">
                            {type === 'auth' ? translation?.typeCode : translation?.scanQrOrEnterCode}
                        </h2>
                        {type === 'enable' && (
                            <div className="form__qr" style={{ width: '100%' }}>
                                {otpCreateSecret && (
                                    <img
                                        className="form__qr-img"
                                        width="178"
                                        height="174"
                                        src={otpCreateSecret?.qrUrl}
                                        alt="qr code"
                                    />
                                )}
                            </div>
                        )}

                        <div className="form__gorup">
                            <div style={{ display: 'flex' }}>
                                <label className="form__label" htmlFor="">
                                    {translation?.googleAuthCode}
                                </label>
                            </div>

                            <div className={`form__codes ${authOtpDataError ? 'error' : ''}`}>
                                {[code1, code2, code3, code4, code5, code6].map((value, idx) => (
                                    <input
                                        key={idx}
                                        ref={codeRefs[idx]}
                                        className="form__code"
                                        maxLength="1"

                                        // 1) Чистый ввод цифр
                                        value={value}
                                        onChange={(e) => handleInputChange(e.target.value, idx)}
                                        onKeyDown={(e) => handleKeyDown(e, idx)}

                                        // 2) Обработка вставки
                                        onPaste={(e) => handlePaste(e, idx)}

                                        // 3) Настройки для type="number"
                                        type="number"
                                        inputMode="numeric"
                                        pattern="[0-9]*"

                                        // Стили для скрытия стрелок можно также добавить через CSS
                                        style={{
                                            MozAppearance: 'textfield'
                                        }}
                                    />
                                ))}
                            </div>
                        </div>
                    </form>
                </div>
            </main>

            <footer className="footer">
                <div className="footer__inner">
                    <div className="footer__btns" style={{ cursor: 'pointer' }}>
                        {isCodeComplete && (
                            <div
                                onClick={() => {
                                    if (type === 'enable') {
                                        fetchEnableOtp(otpCreateSecret?.secret, fullCode);
                                    } else if (type === 'disable') {
                                        fetchDisableOtp(fullCode);
                                    } else if (type === 'auth') {
                                        fetchAuthOtp(fullCode);
                                    }
                                }}
                                className="footer__btn btn"
                            >
                                {translation?.done}
                            </div>
                        )}
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default Settings2Fa;
