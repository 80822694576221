import React, {useEffect, useState} from 'react';
import { useTelegram } from '../../../TelegramContext';
import {useLocation, useNavigate} from "react-router-dom";
import '../../../assets/css/style.css'; // Assuming you have a CSS file for styles
import { LinkProps, Link as RouterLink } from "react-router-dom";

import navBack from '../../../assets/img/icons/nav-back.svg'
import navDots from '../../../assets/img/icons/nav-dots.svg'
import copySvg from '../../../assets/img/icons/copy.svg'
import qrSvg from '../../../assets/img/qr.png'
import selectArrow from '../../../assets/img/icons/select-arrow.svg'
import searchSvg from '../../../assets/img/icons/search.svg'
import swapSvg from '../../../assets/img/icons/swap.svg'

import Menu from "../../../components/Menu";
import useAutoConverts from "../../../hooks/useAutoConverts";
import {useClipboard} from "../../../hooks/use-clipboard.ts";
import useTranslation from "../../../hooks/useTranslation";


const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

function AutoConvertStepThree() {

    const {translation} = useTranslation();

    const { user, triggerVibration } = useTelegram();
    const navigate = useNavigate();
    const {
        autoconvertsSendData, autoconvertsSendDataLoading, autoconvertsSendDataError, fetchSendAutoconvertsData,

        autoConvertAnswer,
        autoConvertLoading,
        autoConvertError,
        fetchAutoConvertById,

        autoConvertDeleteAnswer,
        autoConvertDeleteLoading,
        autoConvertDeleteError,
        fetchAutoConvertDeleteById

    } = useAutoConverts();

    const [isOpenNetwork1,setIsOpenNetwork1] = useState(false);
    const [isOpenToken1,setIsOpenToken1] = useState(false);

    const [isOpenToken2,setIsOpenToken2] = useState(false);
    const [isOpenNetwork2,setIsOpenNetwork2] = useState(false);
    const [currencyfromConvertValue, setCurrencyfromConvertValue] = useState('');

    const query = useQuery();
    const id = query.get('id');

    const currencyfrom = query.get('currencyfrom');
    const currencyfromCryptoChainName = query.get('currencyfromCryptoChainName');

    const currencyto = query.get('currencyto');
    const currencyToCryptoChainName = query.get('currencyToCryptoChainName');

    const currencyToCode = query.get('currencyToCode');
    const currencyFromCode = query.get('currencyFromCode');

    useEffect(()=> {
        fetchAutoConvertById(id)
    }, [id])


    useEffect(() => {
        if (autoConvertAnswer) {
            console.log(autoConvertAnswer, 'autoConvertAnswer')
            // navigate('/auto-convert-step-one')
        }
    }, [autoConvertAnswer]);


    useEffect(() => {
        if (autoConvertDeleteAnswer) {
            console.log(autoConvertDeleteAnswer, 'autoConvertDeleteAnswer')
            navigate('/auto-convert-step-one')
        }
    }, [autoConvertDeleteAnswer]);

    useEffect(() => {
        if (autoConvertError) {
            alert(autoconvertsSendDataError?.message)
            console.log(autoConvertError, 'autoConvertError')
        }
    }, [autoConvertError]);


    useEffect(() => {
            fetch(`https://www.binance.com/api/v3/ticker/price?symbol=LTCUSDT`)
                .then(response => response.json())
                .then(data => {
                    console.log(data?.price, 'currencyfrom-currencyfrom')
                    setCurrencyfromConvertValue(data?.price)
                })
                .catch(error => console.error('Error fetching data:', error));

    }, []);

    const goBack = () => {
        navigate(-1);
    };

    const { handleCopy, isCopied } = useClipboard(
        autoConvertAnswer?.purseAddress,
        'dwdw',
        {
            timeout: 1500,
        }
    );



    return (
        <div className="wrapper">
            {/* Header */}
            <header className="header">
                {/* Navbar */}
                <nav className="nav">
                    <a className="nav__item"  onClick={goBack}>
                        <img
                            className="nav__icon"
                            width="24"
                            height="24"
                            src={navBack}
                            alt="arrow icon"
                        />
                    </a>
                    <Menu/>
                </nav>
            </header>

            {/* Main Page */}
            <main className="main">
                <div className="content">
                    <h1 className="title">{translation?.autoconvert}</h1>

                    <div className="autoconvert">
                        <p className="autoconvert__label">{translation?.autoconversion_address}</p>

                        <div className="autoconvert__group">
                            <div className="autoconvert__row">
                                <p className="autoconvert__text">{translation?.from}:</p>
                                <p className="autoconvert__text">{autoConvertAnswer?.currencyFrom?.cryptoTokenName}</p>
                            </div>
                            <div className="autoconvert__row">
                                <p className="autoconvert__text">{translation?.network}:</p>
                                <p className="autoconvert__text">{autoConvertAnswer?.currencyFrom?.cryptoChainName}</p>
                            </div>
                        </div>






                        <div className="autoconvert__group">
                            <div className="autoconvert__row">
                                <p className="autoconvert__text">{translation?.to}: </p>
                                <p className="autoconvert__text">{autoConvertAnswer?.currencyTo?.cryptoTokenName}</p>
                            </div>
                            <div className="autoconvert__row">
                                <p className="autoconvert__text">{translation?.network}: </p>
                                <p className="autoconvert__text">{autoConvertAnswer?.currencyTo?.cryptoChainName}</p>
                            </div>
                        </div>

                        <div className="autoconvert__group">
                            <div className="autoconvert__row">
                                <p className="autoconvert__text autoconvert__text--center">
                                    {translation?.current_conversion_rate}:
                                </p>
                            </div>
                            <div className="autoconvert__row">
                                <p className="autoconvert__text autoconvert__text--center autoconvert__text--bold">
                                    1 USDT— {currencyfromConvertValue} {currencyfrom}
                                </p>
                            </div>
                        </div>

                        <form className="form form-mb-0" action="#">
                            <div className="form__gorup">
                                <label className="form__label" htmlFor="">{translation?.address}</label>
                                <div className="form__input-gorup">
                                    <input disabled={true} className="form__input" type="text" defaultValue={autoConvertAnswer?.purseAddress}/>
                                    <button
                                        onClick={handleCopy}
                                        type="button" className="form__input-btn"
                                    >
                                        <img
                                            className="form__input-icon"
                                            width="24"
                                            height="24"
                                            src={copySvg}
                                            alt="copy icon"
                                        />
                                    </button>
                                </div>
                            </div>

                            {/*<div className="form__qr" style={{width: '100%'}}>*/}
                            {/*    <img*/}
                            {/*        style={{width:'100%', height: '100%'}}*/}
                            {/*        className="form__qr-img"*/}
                            {/*        width="100%"*/}
                            {/*        height="268"*/}
                            {/*        // src={deposit?.addressQrUrl}*/}
                            {/*        alt="qr code"*/}
                            {/*    />*/}
                            {/*</div>*/}
                        </form>


                    </div>
                </div>

            </main>

            {/* Footer */}
            <footer className="footer">
                <div className="footer__inner">
                    <div className="footer__btns">
                        <div
                            onClick={()=>{
                                navigate('/auto-convert-step-one')
                            }}
                            className="footer__btn btn" href="#"
                        >
                            {translation?.ready}
                        </div>

                        <div
                            onClick={()=>{
                                if (window.confirm(translation?.delete_confirm)) {

                                    fetchAutoConvertDeleteById(id)
                                    // Здесь разместите код, который должен выполниться при подтверждении удаления
                                    console.log("Удаление подтверждено");
                                }
                            }}
                            className="footer__btn btn footer__btn_delete"
                        >
                            {translation?.delete}
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
}

export default AutoConvertStepThree;
