import {useState, useEffect, useRef} from 'react';
import {
    getAutoConvertApi,
    getAutoConvertDeleteApi,
    getAutoconvertsData,
    getFees,
    sendAutoconverts
} from "../utils/userApi";
import {useTelegram} from "../TelegramContext";
import {useNavigate} from "react-router-dom";
import AutoConvertStepThree from "../pages/AutoConvert/AutoConvertSingle";

const useAutoConverts = () => {

    const { user, triggerVibration, webApp  } = useTelegram();

    const [autoconvertsData, setAutoconvertsData] = useState(null);
    const [autoconvertsDataLoading, setAutoconvertsDataLoading] = useState(true);
    const [autoconvertsDataError, setAutoconvertsDataError] = useState(null);

    const [autoconvertsSendData, setAutoconvertsSendData] = useState(null);
    const [autoconvertsSendDataLoading, setAutoconvertsSendDataLoading] = useState(false);
    const [autoconvertsSendDataError, setAutoconvertsSendDataError] = useState(null);

    const isMounted = useRef(false);

    useEffect(() => {
        if (!isMounted.current) {
            fetchGetAutoconvertsData();
            isMounted.current = true;
        }
    }, []);

    const fetchGetAutoconvertsData = async () => {
        setAutoconvertsDataLoading(true)
        const {error, data} = await getAutoconvertsData(null, webApp?.initData);

        if (error) {
            console.log('ERRROR!', error);
            // if (error?.kind == "AUTH_OTP_REQUIRED_ERR") {
            //     // navigate('/settings-2fa?type=auth')
            //     window.location.href = 'settings-2fa?type=auth'
            // }
            setAutoconvertsDataError(error);
            setAutoconvertsDataLoading(false);

        } else {
            setAutoconvertsData(data);
            setAutoconvertsDataLoading(false);

            console.log(data, 'data')

        }

    };



    const fetchSendAutoconvertsData = async (body) => {
        setAutoconvertsSendDataLoading(true)
        const {error, data} = await sendAutoconverts(body, webApp?.initData);

        if (error) {
            console.log('ERRROR!', error);
            setAutoconvertsSendDataError(error);
            setAutoconvertsSendDataLoading(false);

        } else {
            setAutoconvertsSendData(data);
            setAutoconvertsSendDataLoading(false);

            console.log(data, 'data')

        }

    };


    const [feesData, setFeesData] = useState(null);
    const [feesDataLoading, setFeesDataLoading] = useState(true);
    const [feesDataError, setFeesDataError] = useState(null);

    const fetchGetFees = async (body) => {
        setFeesDataLoading(true)
        const {error, data} = await getFees(body, webApp?.initData);

        if (error) {
            console.log('ERRROR!', error);
            setFeesDataError(error);
            setFeesDataLoading(false);

        } else {
            setFeesData(data);
            setFeesDataLoading(false);

            console.log(data, 'data')

        }
    };

    const [autoConvertAnswer, setAutoConvertAnswer] = useState(null);
    const [autoConvertLoading, setAutoConvertLoading] = useState(true);
    const [autoConvertError, setAutoConvertError] = useState(null);

    const fetchAutoConvertById = async (id) => {
        setAutoConvertLoading(true)
        const {error, data} = await getAutoConvertApi(id, webApp?.initData);

        if (error) {
            console.log('ERRROR!', error);
            setAutoConvertError(error);
            setAutoConvertLoading(false);

        } else {
            setAutoConvertAnswer(data);
            setAutoConvertLoading(false);
            console.log(data, 'data')
        }
    };

    const [autoConvertDeleteAnswer, setAutoConvertDeleteAnswer] = useState(null);
    const [autoConvertDeleteLoading, setAutoConvertDeleteLoading] = useState(true);
    const [autoConvertDeleteError, setAutoConvertDeleteError] = useState(null);

    const fetchAutoConvertDeleteById = async (id) => {
        setAutoConvertDeleteLoading(true)
        const {error, data} = await getAutoConvertDeleteApi(id, webApp?.initData);

        if (error) {
            console.log('ERRROR!', error);
            setAutoConvertDeleteError(error);
            setAutoConvertDeleteLoading(false);
        } else {
            setAutoConvertDeleteAnswer(data);
            setAutoConvertDeleteLoading(false);
            console.log(data, 'data')
        }
    };


    return {
        autoconvertsData, autoconvertsDataLoading, autoconvertsDataError, fetchGetAutoconvertsData,
        autoconvertsSendData, autoconvertsSendDataLoading, autoconvertsSendDataError, fetchSendAutoconvertsData,
        feesData,
        feesDataLoading,
        feesDataError,
        fetchGetFees,

        autoConvertAnswer,
        autoConvertLoading,
        autoConvertError,
        fetchAutoConvertById,

        autoConvertDeleteAnswer,
        autoConvertDeleteLoading,
        autoConvertDeleteError,
        fetchAutoConvertDeleteById
    };
};

export default useAutoConverts;
